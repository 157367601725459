import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import './index.css'
import router from './router'
import VueFullscreen from 'vue-fullscreen'

createApp(App)
  .use(ElementPlus)
  .use(VueFullscreen)
  .use(router)
  .mount('#app')
