import layout from '@/components/layout/index'

export default [
  {
    path: '/',
    component: layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        meta: { cache: true, auth: true, name: '合集列表' },
        component: () => import('../components/home/index'),
      },
      {
        path: 'works/:collectionId',
        meta: { cache: true, auth: true, name: '合集素材' },
        component: () => import('../components/works/index'),
      },
    ],
  },
  {
    path: '/user/login',
    meta: { cache: true },
    component: () => import('../components/user/login'),
  },
  {
    path: '/app/txt2Img',
    meta: { cache: true },
    component: () => import('../components/app/txt2Img/index'),
  },
]