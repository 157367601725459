import {createRouter, createWebHistory} from 'vue-router'
import * as storeUser from '@/components/user/store'
import * as storeBag from '@/lib/store/bag'
import * as storeProgress from '@/lib/store/progress'
import routes from './routes';

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {el: to.hash, behavior: 'smooth'};
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return {left: 0, top: 0};
    }
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  try {
    const params = {
      ...to,
      from: from.fullPath,
    };
    storeBag.mutations.setRouteParams(params);
    storeProgress.mutations.setCurrent(0);

    const timestamp = storeUser.state.userInfo &&
      storeUser.state.userInfo.timestamp ||
      0;
    if (!storeUser.state.userInfo || (Date.now() - timestamp > 5*60000)) {
      await storeUser.actions.getUserInfo(true);
    }

    if (to.meta && to.meta.auth && !storeUser.state.userInfo) {
      next('/user/login');
    }

    next();
  } catch(e) {
    console.log(e);
  }
});

export default router