import axios from './axios'
import * as storeProgress from '@/lib/store/progress'
import { ElMessage } from 'element-plus'

export default async function req(...args) {
  let loading = storeProgress.mutations.setLoading;
  if (args && args[0].loadingLocal) {
    const uri = args.length > 0 && (args[0].url || args[0]) || 'unknown';
    loading = (s) => storeProgress.mutations.setLoadingLocal(uri, s);
  } else if (args && args[0].loadingSilent) {
    loading = null;
  }

  try {
    loading && loading(true);
    const res = await axios(...args);
    loading && loading(false);
    return res && res.data;
  } catch(e) {
    loading && loading(false);

    const eData = e && e.response && e.response.data || {};
    const eMsg = e && e.message || e || 'something wrong';
    const error = eData.error || eMsg;
    loading && ElMessage({
      type: 'error',
      message: error,
    });

    if (args && args[0].throwException) {
      throw new Error(error);
    }
  }
}