import {reactive, computed} from 'vue'
import req from '@/lib/req'
import apis from '@/assets/apis'

export const state = reactive({
  filter: {
    collectionName: '',
  },
  perPage: 10,
  collections: [],
});

export const mutations = {
  setPage(page) {
    state.page = Number(page) || 1;
  },
  setCollections(obj) {
    const l = state.collections.filter(o => o.page < obj.page);
    state.collections = [
      ...l,
      obj,
    ];
  },
  resetCollections() {
    state.collections = [];
  },
}

export const actions = {
  async getCollections() {
    const page = state.collections.length + 1;
    const data = await req({
      method: 'POST',
      url: apis.getCollections,
      withCredentials: true,
      data: {
        page,
        perPage: state.perPage,
        filter: state.filter,
      },
    });

    const colls = data && Array.isArray(data.collections) &&
      data.collections.length > 0 &&
      data.collections || null;
    colls && mutations.setCollections({
      page,
      data: colls,
    });
  },
  async addCollection(newCollection) {
    await req({
      method: 'POST',
      url: apis.addCollection,
      withCredentials: true,
      data: {
        newCollection,
      },
    });

    mutations.resetCollections();
    return actions.getCollections();
  },
}

export function useStore() {
  const collections = computed(() => {
    const colls = [...state.collections];
    colls.sort((a, b) => a.page - b.page);

    let ret = [];
    colls.forEach(c => {
      ret = [
        ...ret,
        ...c.data,
      ];
    });

    return ret;
  });

  return {
    collections,
  };
}