<template>
  <div id="app" class="relative"
    v-loading.fullscreen.lock="loading"
    element-loading-background="rgba(0, 0, 0, 0)"
  >
    <div class="w-full fixed top-0 bg-gray-300 z-50">
      <el-progress :percentage="current" v-if="show"
        stroke-linecap="square" :show-text="false"
      />
    </div>
    <router-view />
  </div>
</template>

<script>
import { computed } from 'vue'
import * as storeProgress from '@/lib/store/progress'

export default {
  setup() {
    return {
      loading: computed(() => storeProgress.state.loading),
      current: computed(() => storeProgress.state.current),
      show: computed(() => storeProgress.state.show),
    };
  },
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.el-upload-dragger {
  width: inherit !important;
  height: inherit !important;
  border: none !important;
  background-color: inherit !important;
}
</style>
