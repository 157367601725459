import {reactive} from 'vue'

export const state = reactive({
  current: 0,
  show: false,
  loading: false,
  loadingLocal: false,
})

export const mutations = {
  setCurrent(current) {
    current = Number(current) || 0;
    state.current = Math.min(100, Math.max(0, current));
  },
  setShow(show) {
    state.show = show;
  },
  setLoading(loading) {
    state.loading = loading;
  },
  setLoadingLocal(loadingLocal) {
    state.loadingLocal = loadingLocal;
  },
}
