const utils = require('./utils')

let cfg;
if (utils.isDev() || utils.isLocal()) {
  cfg = {
    server: `http://localhost:${env.port}`,
    resRoot: `http://localhost:${env.port}/res/apps/vavaPreview`,
    streamRoot: `http://localhost:8000`,
  }
} else {
  cfg = {
    server: `https://pre.vava.mgtv.com/s`,
    resRoot: `https://pre.vava.mgtv.com/res/apps/vavaPreview`,
    streamRoot: `https://pre.vava.mgtv.com/stream`,
  }
}

module.exports = cfg