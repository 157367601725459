import {reactive} from 'vue'

export const state = reactive({
  routeParams: {},
  ticket: null,
})

export const mutations = {
  setRouteParams(params) {
    state.routeParams = params || {};
  },
  setTicket(ticket) {
    state.ticket = ticket;
  },
}