import {reactive} from 'vue'
import req from '@/lib/req'
import apis from '@/assets/apis'

export const state = reactive({
  userInfo: null,
  users: [],
});

export const mutations = {
  setUserInfo(info) {
    state.userInfo = info && {
      ...info,
      timestamp: Date.now(),
    } || null;
  },
  setUsers(users) {
    state.users = users || [];
  },
}

export const actions = {
  async getUserInfo(loadingSilent) {
    const info = await req({
      method: 'GET',
      url: apis.getUserInfo,
      withCredentials: true,
      loadingSilent,
    });

    mutations.setUserInfo(info);
  },
  async login({userName, userPass} = {}) {
    const info = await req({
      method: 'POST',
      url: apis.login,
      withCredentials: true,
      data: {userName, userPass},
    });

    mutations.setUserInfo(info);
  },
  async addUser(user) {
    await req({
      method: 'POST',
      url: apis.addUser,
      withCredentials: true,
      data: user,
    });
  },
  async delUserById(userId) {
    await req({
      method: 'POST',
      url: apis.delUserById,
      withCredentials: true,
      data: {userId},
    });
  },
  async getUsers(filter) {
    const users = await req({
      method: 'POST',
      url: apis.getUsers,
      withCredentials: true,
      data: {filter},
    });

    mutations.setUsers(users);
  },
};