<template>
  <div class="flex flex-row items-center justify-between">
    <div class="flex flex-row items-center">
      <div class="cursor-pointer flex flex-row items-center h-full" @click="onBack">
        <el-icon class="mx-4" :size="45"><arrow-left /></el-icon>
      </div>

      <span>{{name}}</span>
    </div>

    <div class="flex flex-row items-center">
      <div class="mr-4">欢迎您, {{userName}}</div>

      <el-dropdown trigger="click" v-if="isAdmin" @command="onCommand">
        <div class="cursor-pointer flex flex-row items-center h-full">
          <el-icon class="mx-4" :size="45"><setting /></el-icon>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item :icon="Plus" command="collection">新增合集</el-dropdown-item>
            <el-dropdown-item :icon="User" command="user">新增用户</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <el-dialog v-model="showUserDiag"
      title="新增用户"
      destroy-on-close
      top="1%"
      width="80%"
      :append-to-body="true"
    >
      <div class="flex flex-col items-start">
        <el-input placeholder="输入用户名"
          v-model="newUser.userName"
          clearable
          size="large"
          class="mb-4"
        >
          <template #prefix>
            <el-icon class="el-input__icon"><user /></el-icon>
          </template>
        </el-input>

        <el-input placeholder="输入用户昵称"
          v-model="newUser.userDisplayName"
          clearable
          size="large"
          class="mb-4"
        >
          <template #prefix>
            <el-icon class="el-input__icon"><user-filled /></el-icon>
          </template>
        </el-input>

        <el-input placeholder="输入用户密码"
          v-model="newUser.pwd"
          clearable
          size="large"
          class="mb-4"
        >
          <template #prefix>
            <el-icon class="el-input__icon"><lock /></el-icon>
          </template>
        </el-input>

        <el-input placeholder="输入用户手机号"
          v-model="newUser.phone"
          clearable
          size="large"
          class="mb-4"
        >
          <template #prefix>
            <el-icon class="el-input__icon"><phone /></el-icon>
          </template>
        </el-input>

        <el-input placeholder="输入用户邮箱"
          v-model="newUser.email"
          clearable
          size="large"
          class="mb-4"
        >
          <template #prefix>
            <el-icon class="el-input__icon"><message /></el-icon>
          </template>
        </el-input>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showUserDiag = false">取消</el-button>
          <el-button type="primary" @click="onAddUser">创建</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="showCollectionDiag"
      title="新增合集"
      destroy-on-close
      top="1%"
      width="80%"
      :append-to-body="true"
    >
      <div class="flex flex-col items-start">
        <el-input placeholder="输入合集名"
          v-model="newCollection.collectionName"
          clearable
          size="large"
          class="mb-4"
        >
          <template #prefix>
            <el-icon class="el-input__icon"><plus /></el-icon>
          </template>
        </el-input>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showCollectionDiag = false">取消</el-button>
          <el-button type="primary" @click="onAddCollection">创建</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {ArrowLeft, Setting, User,
  UserFilled, Lock, Phone, Message, Plus
} from '@element-plus/icons-vue'
import {useRouter, useRoute} from 'vue-router'
import {computed, ref} from 'vue'
import * as storeUser from '@/components/user/store'
import * as storeHome from '@/components/home/store'
import { ElMessage } from 'element-plus'

export default {
  components: {
    ArrowLeft,
    Setting,
    User,
    UserFilled,
    Lock,
    Phone,
    Message,
    Plus,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const name = computed(() => route && route.meta && route.meta.name);
    const userName = computed(() =>
      storeUser.state.userInfo && storeUser.state.userInfo.userDisplayName
    );
    const isAdmin = computed(() =>
      storeUser.state.userInfo && storeUser.state.userInfo.isAdmin
    );
    const showUserDiag = ref(false);
    const newUser = ref({
      userName: '',
      userDisplayName: '',
      pwd: '',
      phone: '',
      email: '',
    });
    const showCollectionDiag = ref(false);
    const newCollection = ref({
      collectionName: '',
    });

    const onBack = () => {
      if (route.path != '/' && route.path != '/home') {
        router.back();
      }
    };

    const onAddUser = async () => {
      if (!newUser.value.userName) {
        return ElMessage({
          type: 'error',
          message: '请输入用户名',
        });
      } else if (!newUser.value.pwd || newUser.value.pwd.length < 8) {
        return ElMessage({
          type: 'error',
          message: '用户密码必须大于等于8位字符',
        });
      }

      await storeUser.actions.addUser(newUser.value);
      showUserDiag.value = false;
      return ElMessage({
        type: 'info',
        message: '已提交',
      });
    };

    const onAddCollection = async () => {
      if (!newCollection.value.collectionName) {
        return ElMessage({
          type: 'error',
          message: '请输入合集名',
        });
      }

      await storeHome.actions.addCollection(newCollection.value);
      showCollectionDiag.value = false;
      return ElMessage({
        type: 'info',
        message: '已提交',
      });
    };

    const onCommand = (command) => {
      if ('collection' == command) {
        showCollectionDiag.value = true;
      } else if ('user' == command) {
        showUserDiag.value = true;
      }
    };

    return {
      User,
      Plus,
      name,
      userName,
      isAdmin,
      showUserDiag,
      newUser,
      showCollectionDiag,
      newCollection,
      onBack,
      onAddUser,
      onAddCollection,
      onCommand,
    }
  },
}
</script>

<style>

</style>