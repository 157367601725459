<template>
  <div class="flex flex-col items-center w-full overflow-auto relative z-30 min-h-screen">
    <comp-head class="w-full h-12" />

    <router-view v-slot="{ Component }">
      <transition name="fade-transform" mode="out-in">
        <div class="flex-1 absolute top-0 pt-12 w-5/6 z-20"
          id="route-ctx"
        >
          <component :is="Component" v-if="!$route.meta.cache" />

          <keep-alive v-if="$route.meta.cache">
            <component :is="Component" />
          </keep-alive>
        </div>
      </transition>
    </router-view>
  </div>
</template>

<script>
import compHead from './head.vue'

export default {
  components: {
    compHead,
  },
}
</script>

<style>

</style>