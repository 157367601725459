const appCfg = require('@/../../assets/appFront')

export default {
  getUserInfo: `${appCfg.server}/user/info`,
  login: `${appCfg.server}/user/login`,
  getUsers: `${appCfg.server}/user/getUsers`,
  addUser: `${appCfg.server}/user/addUser`,
  delUserById: `${appCfg.server}/user/delUserById`,

  getCollections: `${appCfg.server}/resource/getCollections`,
  addCollection: `${appCfg.server}/resource/addCollection`,
  getWorksByCollectionId: `${appCfg.server}/resource/getWorksByCollectionId`,
  getCommentsByWorkId: `${appCfg.server}/resource/getCommentsByWorkId`,
  addCommentByWorkId: `${appCfg.server}/resource/addCommentByWorkId`,
  resolveWorkById: `${appCfg.server}/resource/resolveWorkById`,
  streamWorkById: `${appCfg.server}/resource/streamWorkById`,
  getWorkUsersByWorkId: `${appCfg.server}/resource/getWorkUsersByWorkId`,
  shareWorkToUsers: `${appCfg.server}/resource/shareWorkToUsers`,
  workUpload: `${appCfg.server}/resource/workUpload`,

  txt2Img: `${appCfg.server}/app/txt2Img/txt2Img`,
  getTxt2ImgResult: `${appCfg.server}/app/txt2Img/getTxt2ImgResult`,
}